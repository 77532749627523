import Cart from "@/scripts/models/cart.model";
import CheckoutRequest from "@/scripts/models/checkoutRequest.model";
import CreditCardCartDetails from "@/scripts/models/creditCardCartDetails.model";
import CartService from "@/scripts/services/cart.service";
import LoginService from "@/scripts/services/login.service";
import CartItem from "@/scripts/models/cartItem.model";
import store from "@/store";

const TICKETCART = "ticket-cart";
const CART = "cart";
const cartService = new CartService(null);
const loginService = new LoginService();


const CartStorage = {
    getTicketCart() {
        let cart = localStorage.getItem(TICKETCART);
        return JSON.parse(cart);
    },

    setTicketCart(cart) {
        localStorage.setItem(TICKETCART, JSON.stringify(cart));
    },

    removeTicketCart() {
        localStorage.removeItem(TICKETCART);
    },

    removeCampaignProduct() {
        let cart = this.getCart();
        cart.campaignProduct = null;
        localStorage.removeItem(CART);
        localStorage.setItem(CART, JSON.stringify(cart));
    },

    getCart() {
        const cart = localStorage.getItem(CART);
        const isLogged = loginService.getLoggedUser() ? true : false;

        if (cart) {         
            const parsedCart = JSON.parse(cart);
            
            const today = new Date();
            const lastUpdateDate = new Date(parsedCart.lastUpdateDate);
            const difference = Math.abs(today - lastUpdateDate);
            let totalDays = difference / (1000 * 3600 * 24);

            if (totalDays < 7) return parsedCart;
        }

        const parsedCart = JSON.parse(cart);

        if (parsedCart && isLogged)
            cartService
                .removeCartAndCartItem(parsedCart.id)
                .catch(window.console.error);

        this.clearCart();
        this.createCart();
    },

    createCart() {
        const cart = new Cart();

        cart.checkoutRequest.creditCardInfo.billingAddress.country = "BRA";

        localStorage.setItem(CART, JSON.stringify(cart));
    },

    setCart(cart) {
        if (!cart.checkoutRequest) {
            cart.checkoutRequest = new CheckoutRequest();
            cart.checkoutRequest.creditCardInfo.billingAddress.country = "BRA";
        }

        if (!cart.creditCardCartDetails)
            cart.creditCardCartDetails = new CreditCardCartDetails();

        const code = localStorage.getItem("indication_code");

        cart.checkoutRequest.indicationCode = code || null;

        localStorage.setItem(CART, JSON.stringify(cart));
    },

    addToCart(item, isLogged) {
        const cart = this.getCart();
        item.itemValue = 0;
        if (
            cart.cartItems.filter((e) =>
                item.donationProduct
                    ? e.donationProduct &&
                        e.donationProduct.id == item.donationProduct.id
                    : item.project.id == e.project.id
            ).length == 0
        ) {
            let payloadNotLogged = new CartItem();
            if (isLogged) {
                cartService
                    .addCartItem(
                        item.project.id,
                        item.donationProduct ? item.donationProduct.id : null,
                        false,
                        item.showLoader ? item.showLoader : false
                    )
                    .then(
                        (data) => {
                            var payload = { item: data, isLogged: false };
                            if (item.campaignProduct) {
                                payload.item.campaignProduct =
                                    item.campaignProduct;
                                payload.item.campaignProductId =
                                    item.campaignProduct.id;
                            }

                            store.commit("UPDATE_CART", payload);
                        },
                        (error) => {
                            if (error && error.errorMessage) {
                                this.rollbackAddItem(item, error.errorMessage);
                            }
                        }
                    );
            } else {
                if (item.donationProduct) {
                    payloadNotLogged.donationProduct = item.donationProduct;
                    payloadNotLogged.donationProductId =
                        item.donationProduct.id;
                }

                if (item.project) {
                    payloadNotLogged.project = item.project;
                    payloadNotLogged.projectId = item.project.id;
                }

                if (item.campaignProduct) {
                    payloadNotLogged.campaignProduct = item.campaignProduct;
                    payloadNotLogged.campaignProductId =
                        item.campaignProduct.id;
                }
            }
            cart.cartItems.push(payloadNotLogged);

            if (item.campaignProduct) {
                cart.campaignProduct = item.campaignProduct;
                cart.campaignProductId = item.campaignProduct.id;
            }

            localStorage.setItem(CART, JSON.stringify(cart));
        }
    },

    alterCartCampaignDonationProduct(campaignDonationProductId, isLogged) {
        cartService
            .alterCampaignDonationProductOnCart(campaignDonationProductId)
            .then((data) => {
                const cart = this.getCart();

                cart.campaignProduct = data.campaignProduct;
                cart.campaignProductId = data.campaignProductId;

                store.commit("UPDATE_CART", {
                    item: cart,
                    isLogged,
                });
            });
    },

    removeToCart(payload) {
        var cart = this.getCart();
        if (payload.isLogged) {
            var projectId = payload.item.project.id;
            var donationProductId = payload.item.donationProduct
                ? payload.item.donationProduct.id
                : null;
            cartService
                .removeCartItemByProjectAndDonationProduct(
                    projectId,
                    donationProductId,
                    false,
                    false
                )
                .then({}, (data) => {
                    if (data && data.errorMessage) {
                        this.rollbackRemoveItem(
                            payload.item,
                            data.errorMessage
                        );
                    }
                });
        }
        cart.cartItems = cart.cartItems.filter((e) =>
            e.donationProduct && payload.item.donationProduct
                ? e.donationProduct.id != payload.item.donationProduct.id
                : e.project.id != payload.item.project.id
        );
        localStorage.setItem("cart", JSON.stringify(cart));
    },

    clearCart() {
        localStorage.removeItem(CART);
    },

    rollbackAddItem(item, message) {
        store.commit(
            "SET_SNACKBAR_MESSAGE",
            "Não foi possível adicionar o item ao carrinho. " + message
        );
        var payload = { item: item, isLogged: false };
        this.removeToCart(payload);
        store.commit("REFRESH_CART");
    },

    rollbackRemoveItem(item, message) {
        store.commit(
            "SET_SNACKBAR_MESSAGE",
            "Não foi possível remover o item do carrinho. " + message
        );
        this.addToCart(item, false);
        store.commit("REFRESH_CART");
    },
};

export default CartStorage;
